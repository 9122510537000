import './App.css';
import Routes from "./components/routes/Index"
import MenuState from './context/MenuState'
import { ToastContainer } from "react-toastify";

function App() {
  return (
    <>
      <MenuState>
        <Routes />
      </MenuState>
      <ToastContainer position="top-left" autoClose={2000} hideProgressBar theme="colored" />
    </>
  );
}

export default App;
