import React, { useState, useEffect } from "react";
import { Container, Row, Col, Card, Button, Table, Nav, Modal, Form } from "react-bootstrap";
import Footer from "../layout/Footer";
import PageTitle from '../layout/PageTitle';
import Select from 'react-select'; // Import react-select
import Supervisor from "./Supervisor";
import { getFromAPI } from "../Utils/utils";
import ComplaintTypes from "./ComplaintTypes";
import Wards from "./Wards";
import Zones from "./Zones";


export default function Master() {
    const [activeTab, setActiveTab] = useState("supervisors");
    const [supervisorData, setSupervisorData] = useState();
    const [complaintTypesData, setComplaintTypesData] = useState();
    const [zonesData, setZonesData] = useState();
    const [wardsData, setWardsData] = useState();

    const fetchSupervisorData = async () => {
        try {
            const response = await getFromAPI('supervisor/');
            setSupervisorData(response);
        } catch (error) {
            console.error('Error fetching supervisor data:', error);
        }
    };

    // Add & Edit modal
    const [showModal, setShowModal] = useState(false);
    const [modalTitle, setModalTitle] = useState("");
    const [modalData, setModalData] = useState({});
    const [currentId, setCurrentId] = useState(null);

    const handleShowModal = (title, data = {}, isEdit = false) => {
        setModalTitle(title);
        setModalData(data);
        setCurrentId(isEdit ? data.id : null);
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
        setModalData({});
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setModalData((prev) => ({ ...prev, [name]: value }));
    };

    const handleSelectChange = (name, selectedOptions) => {
        const value = selectedOptions ? selectedOptions.map(option => option.value) : [];
        setModalData((prev) => ({ ...prev, [name]: value }));
    };

    const handleSaveData = () => {
        if (activeTab === "supervisors") {
            if (currentId) {
                // Edit Supervisor
                setSupervisorData((prev) =>
                    prev.map((item) => (item.id === currentId ? { ...modalData, assignedWards: modalData.assignedWards || [] } : item))
                );
            } else {
                // Add Supervisor
                setSupervisorData((prev) => [...prev, { ...modalData, id: supervisorData.length + 1, assignedWards: modalData.assignedWards || [] }]);
            }
        } else if (activeTab === "complaints") {
            if (currentId) {
                // Edit Complaint Type
                setComplaintTypesData((prev) =>
                    prev.map((item) => (item.id === currentId ? modalData : item))
                );
            } else {
                // Add Complaint Type
                setComplaintTypesData((prev) => [...prev, modalData]);
            }
        } else if (activeTab === "zones") {
            if (currentId) {
                // Edit Zone
                setZonesData((prev) =>
                    prev.map((item) => (item.id === currentId ? modalData : item))
                );
            } else {
                // Add Zone
                setZonesData((prev) => [...prev, modalData]);
            }
        } else if (activeTab === "wards") {
            if (currentId) {
                // Edit Ward
                setWardsData((prev) =>
                    prev.map((item) => (item.id === currentId ? modalData : item))
                );
            } else {
                // Add Ward
                setWardsData((prev) => [...prev, modalData]);
            }
        }

        handleCloseModal();
    };

    // const handleDeleteData = (id) => {
    //     if (activeTab === "supervisors") {
    //         setSupervisorData((prev) => prev.filter((item) => item.id !== id));
    //     } else if (activeTab === "complaints") {
    //         setComplaintTypesData((prev) => prev.filter((item) => item.id !== id));
    //     } else if (activeTab === "zones") {
    //         setZonesData((prev) => prev.filter((item) => item.id !== id));
    //     } else if (activeTab === "wards") {
    //         setWardsData((prev) => prev.filter((item) => item.id !== id));
    //     }
    // };
    const [showDeleteModal, setshowDeleteModal] = useState(false);
    const [selectedId, setSelectedId] = useState(null);

    const handleDeleteData = (id) => {
        // Set the selected id and show the modal
        setSelectedId(id);
        setshowDeleteModal(true);
    };
    const handleConfirmDelete = () => {
        // Here you can perform the delete action with the selectedId
        console.log('Deleting item with id:', selectedId);
        // Close the modal
        setshowDeleteModal(false);
    };

    const handleTabInputChange = (selectedTab) => {
        if (selectedTab === "complaints") {
            getFromAPI('category/').then(data => {
                setComplaintTypesData(data);
    
            }).catch(error => {
                console.error('Error fetching complaints:', error);
            });
        }
        setActiveTab(selectedTab)
    };

    const FetchAllZones = () => {
        getFromAPI('zone/').then(data => {
            setZonesData(data);
        }).catch(error => {
            console.error('Error fetching zones:', error);
        });
    }

    const FetchAllWards = () => {
        getFromAPI('ward/').then(data => {
            setWardsData(data.ward);
        }).catch(error => {
            console.error('Error fetching wards:', error);
        });
    }

    useEffect(()=>{
        FetchAllZones();
        FetchAllWards();
    }, [])


    return (
        <>
            <div className="page-wrapper">
                <div className="page-content">
                    <PageTitle breadcrumbLast="Master" />
                    <Container>
                        <Row>
                            <Col md={12}>
                                <Card>
                                    <Card.Body>
                                        <Nav justify variant="pills" className="custom-navtab gap-2" activeKey={activeTab} onSelect={(selectedTab) => handleTabInputChange(selectedTab)}>
                                            <Nav.Item>
                                                <Nav.Link eventKey="supervisors">Supervisors</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link eventKey="complaints">Complaint Types</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link eventKey="zones">Zones</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link eventKey="wards">Wards</Nav.Link>
                                            </Nav.Item>
                                        </Nav>

                                        {activeTab === "supervisors" && (
                                            <Supervisor wardsData={wardsData} zonesData={zonesData} />
                                        )}

                                        {activeTab === "complaints" && (
                                            <>
                                                <ComplaintTypes complaintData={complaintTypesData} />
                                            </>
                                        )}

                                        {activeTab === "zones" && (
                                            <>
                                                <Zones/>
                                            </>
                                        )}

                                        {activeTab === "wards" && (
                                            <>
                                                <Wards/>
                                            </>
                                        )}
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <Footer />
            </div>
            <Modal centered show={showDeleteModal} onHide={() => setshowDeleteModal(false)}>
                <Modal.Body className="text-center">
                    <div className="avatar avatar-xxxl bg-border-soft-danger rounded-circle text-danger mx-auto ">
                        <i className="bi bi-trash"></i>
                    </div>
                    <h4 className="fw-semibold mt-4">Are you sure?</h4>
                    <p className="text-muted fs-18">Do you really want to delete this record?</p>
                    <div className="d-flex gap-3">
                        <Button variant="light w-50" onClick={() => setshowDeleteModal(false)}>
                            Cancel
                        </Button>
                        <Button variant="danger w-50" onClick={handleConfirmDelete}>
                            Delete
                        </Button>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
}
