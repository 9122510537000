import React, { useState, useEffect } from "react";
import { Card, Button, Table, Modal, Form, Col, Row } from "react-bootstrap";
import JSZip from "jszip";
import pdfMake from 'pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import $ from "jquery";
import DataTable from "datatables.net-dt";
import Select from 'react-select'; // Import react-select
import { postToAPI, getFromAPI, putToAPI, deleteFromAPI } from "../Utils/utils";
import { toast } from "react-toastify";

export default function Supervisor({ wardsData, zonesData }) {

    const [showSupervisorModal, setshowSupervisorModal] = useState(false);
    const [modalTitle, setModalTitle] = useState("");
    const [showDeleteModal, setshowDeleteModal] = useState(false);
    const [supervisorData, setSupervisorData] = useState();
    const [supervisorOverallData, setsupervisorOverallData] = useState();
    const [SelectedZone, setSelectedZone] = useState();
    const [WardsBasedOnZone, setWardsBasedOnZone] = useState();
    const [isEdit, setIsEdit] = useState(false);
    const [EditSVID, setEditSVID] = useState();
    const [DeleteSVID, setDeleteSVID] = useState();

    // Form validation
    const [validated, setValidated] = useState(false);
    const [isEmailExists, setisEmailExists] = useState(false);
    const [isContactExists, setisContactExists] = useState(false);

    const initialSupervisorData = {
        email: "",
        mobileNo: "",
        password: "",
        userType: 2, //Currently assume that user type 2 = supervisor
        address: "",
        firstName: "",
        lastName: "",
        employee_id: 0,
        wardId: []
    };

    const [SupervisorInputData, setSupervisorInputData] = useState(initialSupervisorData);

    const fetchSupervisorData = () => {
        getFromAPI('supervisor/').then(data => {
            setSupervisorData(data.supervisors);
        }).catch(error => {
            console.error('Error fetching supervisors:', error);
        });
    }

    const fetchSupervisorOverallData = () => {
        getFromAPI('supervisor/all/').then(data => {
            setsupervisorOverallData(data.supervisors);
        }).catch(error => {
            console.error('Error fetching supervisors:', error);
        });
    }

    const CalculateMaxEmployeeID = () => {
        const maxEmpId = supervisorOverallData.reduce((max, sv) => (sv.employeeId > max ? sv.employeeId : max), 0);
            // Auto increment supervisor id by default
        setSupervisorInputData({...initialSupervisorData, employee_id:(maxEmpId + 1)});
    }

    useEffect(()=>{
        if(supervisorOverallData) {
            CalculateMaxEmployeeID();
        }
    },[supervisorOverallData]);

    const handleEmailChange = (e) => {
        let email_val = e.target.value.trim();
        setSupervisorInputData({ ...SupervisorInputData, email: email_val });
        const emailExists = supervisorData.some(supervisor => supervisor.user_data.email === email_val);
        setisEmailExists(emailExists); 
    }

    const handleContactChange = (e) => {
        let con_val = e.target.value.trim();
        setSupervisorInputData({ ...SupervisorInputData, mobileNo: con_val });
        const contactExists = supervisorData.some(supervisor => supervisor.user_data.mobileNo == con_val);
        setisContactExists(contactExists);      
    }

    const handleAddSupervisor = async (e) => {
        const form = e.currentTarget;
        if (form.checkValidity() === false) {
            e.preventDefault();
            e.stopPropagation();
            setValidated(true);
        } else {
            e.preventDefault(); // Prevent page reload
            setValidated(true);
            if(isEmailExists) {
                toast.error("Entered email is already exists",'error'); 
                return
            }
            if(isContactExists) {
                toast.error("Entered contact number is already exists",'error'); 
                return
            }
            if(isEdit) {
                try {
                    const SVResponse = await putToAPI(`supervisor/${EditSVID}/`, SupervisorInputData);
                    if (SVResponse.status) {
                        UpdateDataTable();
                        toast.success('Supervisor Successfully updated');
                        handleCloseModal();
                    } else {
                        toast.error('Failed to update Supervisor');
                    }
                } catch (error) {
                    console.error('Error:', error);
                    toast.error('An unexpected error occurred');
                }
            } else {
                try {
                    const SVResponse = await postToAPI("signup/", SupervisorInputData);
                    if (SVResponse.status) {
                        UpdateDataTable();
                        toast.success('Supervisor Added Successfully');
                        handleCloseModal();
                    } else {
                        toast.error('Failed to add Supervisor');
                    }
                } catch (error) {
                    console.error('Error:', error);
                    toast.error('An unexpected error occurred');
                }
            }
        }
    }

    const handleAssignZone = (e) => {
        setSelectedZone(e.target.value);
        let wards = wardsData.filter(ward => ward.zone.id == e.target.value);
        setWardsBasedOnZone(wards);
        // Reset ward selection
        setSupervisorInputData(SupervisorInputData => ({ ...SupervisorInputData, wardId: [] }));
    }

    const handleWardChange = (selectedOptions) => {
        const wardIds = selectedOptions.map(option => option.value);
        setSupervisorInputData(SupervisorInputData => ({ ...SupervisorInputData, wardId: wardIds }));
    };

    const handleDeleteData = (id) => {
        // Set the selected id and show the modal
        setDeleteSVID(id);
        setshowDeleteModal(true);
    };
    const handleConfirmDelete = async () => {
        try {
            const Response = await deleteFromAPI(`supervisor/${DeleteSVID}/`);
            if (Response.status) {
                UpdateDataTable();
                toast.success('Supervisor deleted Successfully');
                handleCloseModal();
            } else {
                toast.error('Failed to delete Supervisor');
            }
        } catch (error) {
            console.error('Error:', error);
            toast.error('An unexpected error occurred');
        }
    };

    const EditSupervisor = (supervisor_id) => {
        setEditSVID(supervisor_id);
        const sv_data = supervisorData.find(supervisor => parseInt(supervisor.id) === parseInt(supervisor_id)); 
        const wardIDS = sv_data.ward.map(ward => ward.id);
        setSelectedZone(sv_data.zone.id);
        setSupervisorInputData({...SupervisorInputData, email:sv_data.user_data.email, mobileNo: sv_data.user_data.mobileNo, password: sv_data.user_data.password, firstName: sv_data.FirstName, lastName: sv_data.LastName,employee_id: sv_data.employeeId, wardId: wardIDS});
        handleshowSupervisorModal("Edit Supervisor");
        setIsEdit(true);
    }

    const handleshowSupervisorModal = (title) => {
        setModalTitle(title);
        setshowSupervisorModal(true);
    };

    const handleCloseModal = () => {
        setSupervisorInputData(initialSupervisorData);
        setshowSupervisorModal(false);
        setshowDeleteModal(false)
        setValidated(false);
        setIsEdit(false);
        setEditSVID(null);
        setDeleteSVID(null);
        setSelectedZone();
        CalculateMaxEmployeeID();
    };

    DataTable.Buttons.jszip(JSZip);
    pdfMake.vfs = pdfFonts.pdfMake.vfs;
    useEffect(() => {
        if (supervisorData && !$.fn.dataTable.isDataTable("#supervisorDataTable")) {
            $("#supervisorDataTable").DataTable({
                dom: 'Bflrtip',
                buttons: [
                    {
                        extend: 'excel',
                        className: 'btn btn-export btn-md btn-lightdark',
                        text: '<svg viewBox="0 0 24 24" role="presentation" class="btn-icon"><path d="M14 2H6C4.89 2 4 2.9 4 4V20C4 21.11 4.89 22 6 22H18C19.11 22 20 21.11 20 20V8L14 2M18 20H6V4H13V9H18V20M12.9 14.5L15.8 19H14L12 15.6L10 19H8.2L11.1 14.5L8.2 10H10L12 13.4L14 10H15.8L12.9 14.5Z" style="fill: currentcolor;"></path></svg> Export to Excel'
                    },
                    {
                        extend: 'pdf',
                        className: 'btn btn-export btn-md btn-lightdark',
                        text: '<svg viewBox="0 0 24 24" role="presentation" class="btn-icon"><path d="M6,2A2,2 0 0,0 4,4V20A2,2 0 0,0 6,22H18A2,2 0 0,0 20,20V8L14,2H6M6,4H13V9H18V20H6V4M8,12V14H16V12H8M8,16V18H13V16H8Z" style="fill: currentcolor;"></path></svg> Export to Pdf'
                    },
                    {
                        extend: 'print',
                        className: 'btn btn-export btn-md btn-lightdark',
                        text: '<svg viewBox="0 0 24 24" role="presentation" class="btn-icon"><path d="M19 8H5C3.9 8 3 8.9 3 10V16H7V20H17V16H21V10C21 8.9 20.1 8 19 8M17 18H7V14H17V18M19 12H5V10H19V12Z" style="fill: currentcolor;"></path></svg> Print'
                    }
                ],
                data: supervisorData.map((data, i) => [
                    i + 1,
                    data.employeeId,
                    data.FirstName + " " + data.LastName,
                    data.user_data.mobileNo,
                    data.user_data.email,
                    data.zone.name,
                    data.ward.map((wardData) => wardData.name).join(', '),
                    data.id
                ]),
                columns: [
                    { title: "No." },
                    { title: "Supervisor ID" },
                    { title: "Name" },
                    { title: "Contact" },
                    { title: "Email" },
                    { title: "Assigned Zones" },
                    { title: "Assigned Wards" },
                    {
                        title: "Action",
                        render: function (data, type, row) {
                            return `
                                <div class="d-flex">
                                    <button class="btn btn-soft-primary table-btn me-2"><i class="bi bi-pencil-square edit-supervisor" data-sp-id="${row[7]}"></i></button>
                                    <button class="btn btn-soft-danger table-btn"><i class="bi bi-trash3 delete-supervisor" data-sp-id="${row[7]}"></i></button>
                                </div>
                            `;
                        }
                    },
                ],
                autoWidth: false,
                columnDefs: [
                    {
                        targets: -1,
                        orderable: false,
                    },
                ],
                language: {
                    search: "",
                    searchPlaceholder: "Search...",
                    paginate: {
                        previous: "«",
                        next: "»",
                    },
                },
            });
            // Event delegation to handle delete action
            document.querySelector('#supervisorDataTable tbody').addEventListener('click', function (event) {
                if (event.target && event.target.classList.contains('edit-supervisor')) {
                    const supervisor_id = event.target.getAttribute('data-sp-id');
                    EditSupervisor(supervisor_id);
                }
                if (event.target && event.target.classList.contains('delete-supervisor')) {
                    const supervisor_id = event.target.getAttribute('data-sp-id');
                    handleDeleteData(supervisor_id);
                }
            });

        }
    }, [supervisorData]);

    const UpdateDataTable = () => {
        const PODT = $('#supervisorDataTable').DataTable();
        getFromAPI("supervisor/")
            .then(response => {
                setSupervisorData(response.supervisors);
                let DTData = response.supervisors.map((data, i) => [
                    i + 1,
                    data.employeeId,
                    data.FirstName + " " + data.LastName,
                    data.user_data.mobileNo,
                    data.user_data.email,
                    data.zone.name,
                    data.ward.map((wardData) => wardData.name).join(', '),
                    data.id
                ]);
            PODT.clear().rows.add(DTData).draw();
            })
            .catch(error => {
            console.error('Error:', error);
            });
    }

    useEffect(() => {
        fetchSupervisorData();
        fetchSupervisorOverallData();
    }, []);

    return (
        <>
            <div className="d-flex align-items-center flex-wrap gap-2 justify-content-between my-3">
                <Card.Title className="mb-0">Supervisors</Card.Title>
                <Button variant="primary" onClick={() => handleshowSupervisorModal("Add Supervisor")}>
                    <i className="bi bi-plus-square me-2"></i> Add Supervisor
                </Button>
            </div>

            {/* Zone and Ward Filters */}

            {/* <div className="d-inline-flex gap-2 mb-3">
                <Form.Select aria-label="Default select example" size="sm">
                    <option>Select Zone</option>
                    {zonesData && zonesData.map((zone) => (
                        <option key={zone.id} value={zone.id}>
                            {zone.name}
                        </option>
                    ))}
                </Form.Select>
                <Form.Select aria-label="Default select example" size="sm">
                    <option>Select Ward</option>
                    {wardsData && wardsData.map((ward) => (
                        <option key={ward.id} value={ward.id}>
                            {ward.name}
                        </option>
                    ))}
                </Form.Select>
            </div> */}

            <Table responsive bordered className="mb-0 table-nowrap" id="supervisorDataTable">
                <thead className="table-light">
                </thead>
            </Table>

            <Modal centered size="lg" show={showSupervisorModal} onHide={handleCloseModal}>
                <Modal.Header closeButton>
                    <Modal.Title>{modalTitle}</Modal.Title>
                </Modal.Header>
                <Form noValidate validated={validated} onSubmit={(e) => handleAddSupervisor(e)}>
                    <Modal.Body className="pb-0">
                        <Row>
                            <Col lg={6}>
                                <Form.Group className="form-space">
                                    <Form.Label>First Name</Form.Label>
                                    <Form.Control type="text" name="name" placeholder="Enter first name..." value={SupervisorInputData.firstName || ''} onChange={(e) => setSupervisorInputData({ ...SupervisorInputData, firstName: e.target.value.trim() })} required isInvalid={validated && !SupervisorInputData.firstName}/>
                                    <Form.Control.Feedback type="invalid">
                                        Please enter Firstname.
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            <Col lg={6}>
                                <Form.Group className="form-space">
                                    <Form.Label>Last Name</Form.Label>
                                    <Form.Control type="text" name="name" placeholder="Enter last name..." value={SupervisorInputData.lastName || ''} onChange={(e) => setSupervisorInputData({ ...SupervisorInputData, lastName: e.target.value.trim() })} required isInvalid={validated && !SupervisorInputData.lastName} />
                                    <Form.Control.Feedback type="invalid">
                                        Please enter Lastname.
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            <Col lg={7}>
                                <Form.Group className="form-space">
                                    <Form.Label>Email</Form.Label>
                                    <Form.Control type="email" name="email" placeholder="Enter email address..." value={SupervisorInputData.email || ''} onChange={(e) => handleEmailChange(e)} required isInvalid={validated && isEmailExists} isValid={validated && !isEmailExists} />
                                    <Form.Control.Feedback type="invalid">
                                        {isEmailExists ? "Email already exists" : "Please enter valid email."}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            <Col lg={5}>
                                <Form.Group className="form-space">
                                    <Form.Label>Contact</Form.Label>
                                    <Form.Control type="text" name="contact" placeholder="+91 863-456-7890" value={SupervisorInputData.mobileNo || ''} onChange={(e) => handleContactChange(e)} required isInvalid={validated && isContactExists} isValid={validated && !isContactExists} />
                                    <Form.Control.Feedback type="invalid">
                                        {isContactExists ? "Entered contact number is already exists." : "Please enter valid contact number."}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            <Col lg={6}>
                                <Form.Group className="form-space">
                                    <Form.Label>Supervisor ID</Form.Label>
                                    <Form.Control type="text" name="id" placeholder="001" defaultValue={SupervisorInputData.employee_id} readOnly />
                                </Form.Group>
                            </Col>
                            <Col lg={6}>
                                <Form.Group className="form-space">
                                    <Form.Label>Password</Form.Label>
                                    <Form.Control type="password" name="id" placeholder="Enter password..." value={SupervisorInputData.password || ''} onChange={(e) => setSupervisorInputData({ ...SupervisorInputData, password: e.target.value.trim() })} required isInvalid={validated && !SupervisorInputData.password}/>
                                    <Form.Control.Feedback type="invalid">
                                        Please enter Password.
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            <Col lg={4}>
                                <Form.Group className="form-space">
                                    <Form.Label>Assigned Zones</Form.Label>
                                    <Form.Select as="select" name="assignedZones" value={SelectedZone} onChange={(e) => handleAssignZone(e)} required isInvalid={validated && !SelectedZone} disabled={isEdit} > 
                                        <option value="">Select Zone</option>
                                        {zonesData && zonesData.map((zone) => (
                                            <option key={zone.id} value={zone.id}>
                                                {zone.name}
                                            </option>
                                        ))}
                                    </Form.Select>
                                    <Form.Control.Feedback type="invalid">
                                        Please Select zone.
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            <Col lg={8}>
                                <Form.Group className="form-space custom-form-input">
                                    <Form.Label>Assigned Wards</Form.Label>
                                    <Select isMulti name="assignedWards" onChange={handleWardChange}
                                        value={wardsData && wardsData.filter(ward => SupervisorInputData.wardId.includes(ward.id)).map(ward => ({
                                            value: ward.id,
                                            label: ward.name
                                        }))}
                                        options={WardsBasedOnZone && WardsBasedOnZone.map((ward, i) => ({
                                            value: ward.id,
                                            label: ward.name
                                        }))}
                                        required isInvalid={validated && !SupervisorInputData.wardId}
                                        isDisabled={isEdit}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Please select ward.
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                        </Row>
                    </Modal.Body>
                    <Modal.Footer className="mt-1">
                        <Button type="submit" variant="primary" >Save</Button>
                        <Button variant="light" onClick={handleCloseModal}>Close</Button>
                    </Modal.Footer>
                </Form>
            </Modal>

            <Modal centered show={showDeleteModal} onHide={handleCloseModal}>
                <Modal.Body className="text-center">
                    <div className="avatar avatar-xxxl bg-border-soft-danger rounded-circle text-danger mx-auto ">
                        <i className="bi bi-trash"></i>
                    </div>
                    <h4 className="fw-semibold mt-4">Are you sure?</h4>
                    <p className="text-muted fs-18">Do you really want to delete this record?</p>
                    <div className="d-flex gap-3">
                        <Button variant="light w-50" onClick={handleCloseModal}>
                            Cancel
                        </Button>
                        <Button variant="danger w-50" onClick={handleConfirmDelete}>
                            Delete
                        </Button>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}
